body {
  margin: 0;
  padding: 64px 15% 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav {
  display: flex;
  gap: 10px;
}

@keyframes in-keyframes {
  0% {
    opacity: 0;
    translate: 0 100%;
  }
  100% {
    opacity: 1;
    translate: 0 0;
  }
}

.animate-in {
  animation-name: in-keyframes;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

/* wave link styles */
body .react-bubbly-transitions__bubbly-link {
  padding: 0;
  outline: none;
}

body .react-bubbly-transitions__bubbly-link.active {
  position: relative;
  color: #8f44fd;
}

body .react-bubbly-transitions__bubbly-link.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  border-radius: 3px;
  background: #8f44fd;
}
